<template>
  <a-layout-header class="herader">
    <router-link style="color:white" to="/book">智能英语学习系统</router-link>
    <div class="user_box my_pointer">
      <a-avatar class="user_img" :src="avatar" />
      <span class="user_name">{{ name }}</span>
      <span @click="logoOut" class="logo_out">
        <a-icon type="logout" />
        退出系统
      </span>
    </div>
  </a-layout-header>
</template>

<script>
import {
  showMyModal,
  getUserInfo,
  setToken,
  setHash,
  getCompany,
  setUserInfo
} from '../utils/common';
import { userLogout } from '../api/service';
const userInfo = getUserInfo('user_info')
  ? JSON.parse(getUserInfo('user_info'))
  : {};
const { name, avatar } = userInfo;
export default {
  data() {
    return { name, avatar };
  },
  methods: {
    logoOut() {
      showMyModal('确定要退出吗?', () => {
        userLogout();
        setToken('');
        setHash(getCompany());
        setUserInfo('');
        this.$router.push(`/user/${getCompany()}/login`);
      });
    }
  }
};
</script>

<style scoped lang="less">
.herader {
  height: 70px;
  line-height: 70px;
  width: 100%;
  color: white;
  padding-left: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .user_box {
    display: flex;
    align-items: center;
    .user_img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .user_name,
    .logo_out {
      line-height: 1;
      font-size: 20px;
    }
    .logo_out {
      margin-left: 20px;
      font-size: 0.9rem;
    }
  }
}
</style>
