<template>
  <div class="footer">
    @ 2019
    <span class="my_pointer" @click="toHomePage">YYASHOW</span>
    All rights reserved.
  </div>
</template>

<script>
export default {
  methods: {
    toHomePage() {
      window.open('https://www.yyashow.com');
    }
  }
};
</script>

<style scoped>
.footer {
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
</style>
