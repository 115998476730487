<template>
  <a-layout class="my_layout" id="components-layout-demo-side">
    <a-layout>
      <Header></Header>
      <a-layout-content class="all_content my_pointer" id="all_content">
        <my-loading></my-loading>
        <router-view v-if="isRouterAlive"></router-view>
      </a-layout-content>
      <Footer></Footer>
    </a-layout>
  </a-layout>
</template>

<script>
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
// import BreadCrumb from '../components/BreadCrumb';
import { getToken, getCompany } from '../utils/common';
import MyLoading from '../components/MyLoading';
export default {
  components: {
    Footer,
    Header,
    MyLoading
    // BreadCrumb
  },
  provide() {
    return {
      reload: this.reload
    };
  },

  data() {
    return {
      isRouterAlive: true
    };
  },

  mounted() {
    if (!getToken()) {
      this.$router.replace(`/user/${getCompany()}/login`);
    }
  },

  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    }
  }
};
</script>

<style scoped lang="less">
.my_layout {
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  position: absolute;
}
.all_content {
  display: flex;
  flex-direction: column;
  flex: 2;
  position: relative;
  margin: 16px auto;
  width: 90%;
}
</style>
